var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBLDETAIL" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: false,
                        readonly: true,
                        label: "작성정보",
                        name: "reg",
                      },
                      model: {
                        value: _vm.reg,
                        callback: function ($$v) {
                          _vm.reg = $$v
                        },
                        expression: "reg",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-radio", {
                      attrs: {
                        editable: false,
                        codeGroupCd: "SAI_INTERNAL_ACTION_TYPE_CD",
                        label: "심사구분",
                        name: "col1",
                      },
                      model: {
                        value: _vm.action.col1,
                        callback: function ($$v) {
                          _vm.$set(_vm.action, "col1", $$v)
                        },
                        expression: "action.col1",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-plant", {
                      attrs: { editable: false, name: "plantCd" },
                      model: {
                        value: _vm.action.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.action, "plantCd", $$v)
                        },
                        expression: "action.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: false,
                        rows: 10,
                        label: "심사 목적 및 범위",
                        name: "col3",
                      },
                      model: {
                        value: _vm.action.col3,
                        callback: function ($$v) {
                          _vm.$set(_vm.action, "col3", $$v)
                        },
                        expression: "action.col3",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: false,
                        rows: 10,
                        label: "심사 준비 요청사항",
                        name: "col2",
                      },
                      model: {
                        value: _vm.action.col2,
                        callback: function ($$v) {
                          _vm.$set(_vm.action, "col2", $$v)
                        },
                        expression: "action.col2",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "심사팀 목록",
              columns: _vm.gridTeam.columns,
              data: _vm.action.auditTeams,
              gridHeight: _vm.gridTeam.height,
              editable: false,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              isFullScreen: false,
              columnSetting: false,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }